import CloseIcon from "@mui/icons-material/Close";
import { apiCallDelete } from "../../../utils/action";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { getCommentLibraryList } from "../../../store/action";
const CLDeleteTagsConfirm = ({ setDeleteLoader, deleteLoader, handleCloseConfirmationModal, deleteConfirmationModal, deletedTagId, setDeletedTagId, GetCommentLibTagList }) => {
    const dispatch = useDispatch();
    const handleDeleteTag =  () => {
        setDeleteLoader(true)
        const payload = {
            "tag_id": deletedTagId,
        };
        apiCallDelete(
            (response) => {
                const { success, message } = response.data;
                if (success) {
                    setDeletedTagId("")
                    handleCloseConfirmationModal(false);
                    GetCommentLibTagList();
                    dispatch(getCommentLibraryList(1));
                    setDeleteLoader(false)
                } else {
                    console.error("Update failed:", message);
                    setDeleteLoader(false)
                }
            },
            payload,
            "DELETE_TAG"
        );
    }
    return (<Dialog
        open={deleteConfirmationModal}
        onClose={handleCloseConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="delete-alert-modal"
    >
        <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion?"}
            <CloseIcon className="close-icon" onClick={handleCloseConfirmationModal} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this Tag? It will also be removed from the comments it is assigned to.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className='options' onClick={handleCloseConfirmationModal}><span>No</span></Button>
            <LoadingButton loading={deleteLoader} className='add-new-btn' onClick={handleDeleteTag}><span>Yes</span></LoadingButton>
        </DialogActions>
    </Dialog>)
}
export default CLDeleteTagsConfirm;