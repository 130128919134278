// File imports
import PropTypes from "prop-types";
// import AddNewTag from './AddNewTag';
import CLColorPicker from "./ColorPicker";
import { visuallyHidden } from "@mui/utils";
import AddIcon from "@mui/icons-material/Add";
// import Preloader from '../../../helpers/Preloader';
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import CLTagsButtonGroup from './CLTagsButtonGroup';
// import { timestamp } from '../../../utils/services';
import { stableSort } from "./ColorIndicatorHelper";
import { apiCall } from "../../utils/action";
import { timestamp } from "../../utils/services";
// import CLDeleteTagsConfirm from './CLDeleteTagsConfirm';
import React, { useEffect, useMemo, useState, useRef } from "react";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
// import { apiCallGet, apiCallPut } from '../../../utils/action';
import "../../../assets/styles/containers/_colorIndicators.scss";
// import "../../../../assets/styles/containers/_clTagsSummary.scss";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import "../../../../assets/styles/containers/_clTagsSummary.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Paper, Button, Box, Grid, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableRow, IconButton, TableHead, TableSortLabel, Divider, Alert } from "@mui/material";
import uuid from "react-uuid";
import RSColorPicker from "../../pages/settings/ReportSettings/RSColorPicker";

/**
 *
 * TabPanel
 *
 * Renders a tab panel for the Comment Library
 */
function TabPanel(props) {
  // Destructuring props
  const { children, value, index, ...other } = props;
  // JSX
  return (
    <div
      role="tabpanel"
      hidden={value !== index} // Hides the tab panel when it's not selected
      id={`simple-tabpanel-${index}`} // Generates a unique ID for each tab panel
      aria-labelledby={`simple-tab-${index}`} // The ID of the tab
      {...other} // Spreads any other properties
    >
      {/* Renders the tab panel when it's selected */}
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography> {/* Renders the tab's content */}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node, // The content of the tab
  index: PropTypes.number.isRequired, // The index of the tab
  value: PropTypes.number.isRequired, // The selected tab
};
/**
 * Creates a comparator function for sorting rows.
 *
 * @param {string} order - The sort order, either 'asc' or 'desc'
 * @param {string} orderBy - The property to sort by
 * @returns {function} - The comparator function
 */
function getComparator(order, orderBy) {
  /**
   * Comparator function that compares two rows based on the orderBy property
   *
   * @param {object} a - The first row
   * @param {object} b - The second row
   * @returns {number} - A negative number if a < b, positive if a > b, or 0 if equal
   */
  const descendingComparator = (a, b, orderBy) => {
    // If the property is a string, lowercase both values to compare
    if (typeof a[orderBy] === "string") {
      const lowercaseA = a[orderBy].toLowerCase();
      const lowercaseB = b[orderBy].toLowerCase();

      if (lowercaseA < lowercaseB) {
        return -1;
      } else if (lowercaseA > lowercaseB) {
        return 1;
      }
    }
    // If the property is a number, compare normally
    if (a[orderBy] < b[orderBy]) {
      return -1;
    } else if (a[orderBy] > b[orderBy]) {
      return 1;
    }
    // Items are equal
    return 0;
  };
  // Based on the order, sort the rows in either ascending or descending order
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
/**
 * Component for displaying Comment Library table
 */
const CITable = ({ updateTemplate, AllCategoryList, LoadList, tableData, templateGUID, templateType, CloseOpenCI, setColorIndicatorList }) => {
  // state hooks
  const [order, setOrder] = useState("asc"); // asc or desc order
  const [selected, setSelected] = useState([]); // selected rows
  const [orderBy, setOrderBy] = useState("calories"); // currently ordered by column
  const [showLoader, setShowLoader] = useState(false); // show loader indicator
  const [deletedTagId, setDeletedTagId] = useState(""); //id for delete tag
  const [commentListData, setCommentListData] = useState(tableData || []); // comment list data
  const [initialTableData, setInitialTableData] = useState([]); // initial table data to perform search
  const handleopenAddNewTagModal = () => setopenAddNewTagModal(true); //modal toggle
  const [openAddNewTagModal, setopenAddNewTagModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#334B8E");
  const handleCloseAddNewTagModal = () => setopenAddNewTagModal(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [commentListInititalData, setCommentListInitialData] = useState([]); // initial comment list data
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const colorPalette = ["#6C2181", "#851313", "#C64B00", "#195803", "#0E4D5C", "#2F0881", "#038787", "#475803", "#571E1E", "#334B8E"];
  const handleOpenConfirmationModal = (row) => {
    setDeleteConfirmationModal(true);
    setDeletedTagId(row);
  };
  const handleCloseConfirmationModal = () => setDeleteConfirmationModal(false);

  // API calls
  const GetCommentLibTagList = () => {
    /**
     * Fetch comment list data from API
     * @param {number} pageNumber current page number
     */
    setShowLoader(true);
    // apiCallGet(
    //     (response) => {
    //         const { data, message, success } = response.data;
    //         if (success) {
    //             setCommentListData(data);
    //             setInitialTableData(data)
    //             setCommentListInitialData(data);
    //             setShowLoader(false);
    //         } else {
    //             console.log("else", message);
    //         }
    //     },
    //     "",
    //     "GET_COMMENT_LIB_TAGS_LIST"
    // );
  };

  function getRandomHexColor() {
    // Define a set of hex codes
    const hexColors = [
      "#FF5733", // Orange-red
      "#33FF57", // Green
      "#5733FF", // Blue
      "#F1C40F", // Yellow
      "#E74C3C", // Red
      "#8E44AD", // Purple
      "#1ABC9C", // Teal
    ];

    // Generate a random index
    const randomIndex = Math.floor(Math.random() * hexColors.length);

    // Return the randomly selected hex color
    return hexColors[randomIndex];
  }

  const handleElementChange = async (event, row, type) => {
    let updatedData;
    let updatedElementValue;
    if (type === "TEXT") {
      updatedElementValue = event.target.value;
      updatedData = commentListData.map((element) => (element.guid === row.guid ? { ...element, name: updatedElementValue, updated_at: timestamp() } : element));
    } else if (type === "COLOR") {
      updatedElementValue = event;
      updatedData = commentListData.map((element) => (element.guid === row.guid ? { ...element, color_code: updatedElementValue, updated_at: timestamp() } : element));
    } else if (type === "ADD") {
      scrollDivToBottom();
      updatedElementValue = {
        color_code: getRandomHexColor(),
        name: "Untitled",
        guid: uuid(),
        template_type: templateType,
        template_guid: templateGUID,
        order: commentListData.length + 1,
        updated_at: timestamp(),
        id: commentListData[commentListData?.length - 1]?.id + 1,
      };
      updatedData = [...commentListData, updatedElementValue];
      // handleADD(updatedData);
    } else if (type === "DELETE") {
      updatedData = commentListData.map((element) => (element.guid === row.guid ? { ...element, is_deleted: 1, updated_at: timestamp() } : element));
    }
    setCommentListData(updatedData);
  };
  const handleADD = async (data) => {
    const payload = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      inspector_flag_list: data,
      template_guid: templateGUID,
      template_type: templateType,
    };
    try {
      const response = await apiCall(
        (response) => {
          const { success, message, data } = response.data;
          if (success) {
            setColorIndicatorList(data);
            setColorIndicatorList(data);
          } else {
            console.error("Update failed:", message);
          }
        },
        payload,
        "GET_CI_LIST"
      );
    } catch (error) {
      console.error("Error updating summary:", error);
    }
  };
  const handleDone = async () => {
    let data = AllCategoryList?.template?.inspector_flag_list;
    data?.splice(0, data?.length, ...commentListData)
    CloseOpenCI();
    if (AllCategoryList?.template) {
      AllCategoryList.template.updated_at = timestamp();
    }
    updateTemplate("noToast");
  };

  // componentDidMount equivalent using useEffect
  useEffect(() => {
    if (sessionStorage.getItem("company-key")) {
      GetCommentLibTagList();
    }
    // eslint-disable-next-line
  }, [sessionStorage.getItem("company-key")]);

  const handleSearchFilter = (event) => {
    /**
     * Handle search functionality
     * @param {string} event search field value
     */
    const query = event.toLowerCase();
    if (query === "") {
      setCommentListData(commentListInititalData);
    } else {
      const searchData = initialTableData?.filter((item) => {
        return item?.title?.toLowerCase()?.includes(query);
      });
      setCommentListData(searchData);
    }
  };
  // Handle head cells, sorting and selection
  const EnhancedTableHead = (props) => {
    /**
     * Handle table head cells, sorting and selection
     * @param {object} props table head props
     */
    // eslint-disable-next-line
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells?.map((headCell) => (
            <TableCell key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} sortDirection={orderBy === headCell.id ? order : false} className="cl-table-head">
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() => (headCell.id !== "order" && headCell.id !== "global" ? <UnfoldMoreIcon className="cl-sort-icon" /> : "")}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const headCells = [
    {
      id: "order",
      numeric: false,
      disablePadding: false,
      label: "  ",
    },
    {
      id: "title",
      numeric: false,
      disablePadding: true,
      label: "Tag",
    },

    {
      id: "color",
      numeric: false,
      disablePadding: false,
      label: "Color",
    },
    {
      id: "global",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const selectedComment = commentListData?.map((n) => n.id);
      setSelected(selectedComment);
      return;
    }
    setSelected([]);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const visibleRows = useMemo(
    () => stableSort(commentListData, getComparator(order, orderBy)),
    // eslint-disable-next-line
    [order, orderBy, commentListData]
  );
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(commentListData, result.source.index, result.destination.index);
    items.forEach((element, i) => {
      element.order = i + 1;
      // element.updated_at = timestamp();
      return element;
    });
    setCommentListData(items);
  };

  const containerRef = useRef(null);

  const scrollDivToBottom = () => {
    if (containerRef.current) {
      setTimeout(() => {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }, 0);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", maxHeight: "400px", border: "1px solid #D5D5D5", borderRadius: "3px" }}>
        <Paper sx={{ width: "100%" }} className="cl-table" elevation={0}>
          <TableContainer
            elevation={0}
            ref={containerRef}
            component={Paper}
            id="table_container"
            className="inspections-filter-toolbar"
            style={{ overflowY: "scroll", minHeight: "355px", maxHeight: "356px", minWidth: "700px", scrollBehavior: "smooth" }}>
            {commentListData?.filter((e) => e?.is_deleted !== 1)?.length > 0 ? (
              <Table aria-label="simple table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  rowCount={commentListData?.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {visibleRows
                          ?.filter((e) => e?.is_deleted !== 1)
                          ?.map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                tabIndex={-1}
                                key={row.guid}
                                ref={provided.innerRef}
                                selected={isItemSelected}
                                sx={{ cursor: "pointer" }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                aria-checked={isItemSelected}>
                                <TableCell component="th" id={labelId} scope="row" size="small" padding="none" sx={{ padding: "20px 0px 0px 15px" }} className="cl-row-status cl-exp-col">
                                  <IconButton>
                                    <DragIndicatorIcon sx={{ color: "#878787", mt: 1.5 }} fontSize="small" />
                                  </IconButton>
                                </TableCell>
                                <TableCell padding="none" sx={{ p: "0px 10px 0px 10px" }} className="cl-title-col" align="left">
                                  <TextField className="cl_tags_text" defaultValue={row.name} sx={{ mb: 1 }} onBlur={(e) => handleElementChange(e, row, "TEXT")} />
                                </TableCell>
                                <TableCell
                                  // sx={{ padding: "10px 10px 0px 10px" }}
                                  padding="none"
                                  // className="cl-global-col"
                                  align="left"
                                  style={{ width: "200px", position: "relative", zIndex: 99999999 }}>
                                  <CLColorPicker row={row} onColorChange={handleElementChange} GetCommentLibTagList={GetCommentLibTagList} />
                                </TableCell>
                                <TableCell padding="none" align="right" width="160px" sx={{ position: "relative", paddingLeft: "15px" }}>
                                  <div className="button_group_tab" onClick={(e) => handleElementChange(e, row, "DELETE")}>
                                    <svg width="14" height="15" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      {/* Return path for the delete button icon */}
                                      <path
                                        d="M3 18C2.45 18 1.97933 17.8043 1.588 17.4125C1.196 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.0208 14.4125 17.4125C14.0208 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                                        fill="#D63D3D"
                                      />
                                    </svg>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            ) : (
              <Alert severity="info">No data found</Alert>
            )}
          </TableContainer>
        </Paper>
        <Divider />
        <div style={{ padding: "10px 20px", marginTop: "-5px" }}>
          <Button
            className="ci_add_button"
            onClick={(e) => {
              handleElementChange(e, "", "ADD");
              scrollDivToBottom("table_container");
            }}
            startIcon={<AddCircleOutlineIcon />}>
            Add new
          </Button>
        </div>
      </Box>
      <Grid container justifyContent={"right"} justifyItems="right">
        <Grid item xs={6} className="team-detail-cta">
          <Button className="back-btn" onClick={CloseOpenCI}>
            Cancel
          </Button>
          <Button
            onClick={handleDone}
            className="default-btn teams-detail-btn"
            id="agentdetails-savebutton"
            sx={{ height: "34px !important", width: "119px !important" }}>
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CITable;
