import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import TextField from "@mui/material/TextField";
import { filterObjectsUptoCategory, timestamp } from "../../../utils/services";

const TextInput = ({ AllCategoryList, updateTemplate, selectedID, data, categoryId }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.templates)?.categoryList;
  const defaultText = Array.isArray(data) ? "" : data;
  const [value, setValue] = useState(defaultText);
  const [edit, setEdit] = useState(false);
  const CateList = AllCategoryList.template.categories;
  let insp = CateList.find((insp) => insp.is_inspection_details === 1);
  const linesdata = insp.lines;
  const valData = linesdata.find((line) => line.line_input_controls[0].line_input_control_id === selectedID);

  const handleChange = (event) => {
    setEdit(true);
    setValue(event.target.value);
  };

  const auto = () => {
    valData.line_input_controls[0].values.push(value);
    valData.line_input_controls[0].data = value;
    valData.line_input_controls[0].updated_at = timestamp();
    filterObjectsUptoCategory(CateList, categoryId, dispatch, categories);
    updateTemplate("noToast");
  };

  const bubble = () => {
    if (edit) {
      auto();
      setEdit(false);
    }
  };

  return (
    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => bubble()}>
      <Grid className="temprature" sx={{ width: "100% !important", marginBottom: "30px" }}>
        <TextField
          sx={{ pl: "0px !important" }}
          className="input-textfield"
          fullWidth
          size="small"
          value={value === "" ? "Default text" : value}
          onKeyDown={(e) => e.keyCode === 13 && auto()}
          onChange={handleChange}
          defaultValue={"Default text"}
          variant="outlined"
        />
      </Grid>
    </ClickAwayListener>
  );
};

export default TextInput;
