import axios from "axios";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useRef, useState } from "react";
import ReportEditLayout from "./ReportEditLayout";
import { useSearchParams } from "react-router-dom";
import Preloader from "../../../helpers/Preloader";
import config_settings from "../../../config/config";
import ReportPreviewPages from "./ReportPreviewPages";
import { Box, Card, Grid, Typography } from "@mui/material";
import "../../../../assets/styles/containers/_reportSettings.scss";

let REPORT_SETTING_URL;

const origin = () => {
  let origin = window.location.origin.toLowerCase();
  console.log(origin);
  if (origin.includes("https://")) origin = origin.replace("https://", "");
  else origin = origin.replace("http://", "");
  REPORT_SETTING_URL = config_settings[origin].REPORT_SETTING_URL;
  console.log("origin", origin);
  console.log("REPORT_SETTING_URL", REPORT_SETTING_URL);
  // return origin;
};
origin();

const ReportSettingsLayout = ({ key }) => {
  const fileInput = useRef();
  const [image, setImage] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [lastPublished, setLastPublished] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [defaultLogo, setDefaultLogo] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [useCompanyName, setUseCompanyName] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#334B8E");
  const [companyName, setCompanyName] = useState("company name");
  const colorPalette = ["#6C2181", "#851313", "#C64B00", "#195803", "#0E4D5C", "#2F0881", "#038787", "#475803", "#571E1E", "#334B8E"];

  useEffect(() => {
    setShowLoader(true);
    if (sessionStorage.getItem("company-key") === null || sessionStorage.getItem("company-key") === "null") {
      sessionStorage.setItem("company-key", searchParams.get("key"));
      searchParams.delete("key");
      setSearchParams(searchParams);
    }
    const Header = {
      headers: {
        "company-key": sessionStorage.getItem("company-key"),
      },
    };
    axios
      .post(`${REPORT_SETTING_URL}/get-company-html-report-settings`, "", Header)
      .then((res) => {
        const { data, last_updated_at } = res.data;
        setImage("");
        setDefaultLogo(data.company_logo_url === null ? null : data.company_logo_url);
        setSelectedColor(data.color_code ? data.color_code : "#334B8E");
        setCompanyName(data.company_name);
        setUseCompanyName(data.use_company_name_as_logo === 1 ? true : false);
        setShowLoader(false);
        setLastPublished(last_updated_at);
      })
      .catch(function (error) {
        if (error.response) {
          setShowLoader(false);
          setDefaultLogo(null);
          setSelectedColor("#334B8E");
          fileInput.current.value = "";
          if (error.response.data.status_code !== 404) {
            toast.error(error.response.data.message);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }, [refresh]);

  const sendSettings = () => {
    setShowLoader(true);
    const Header = {
      headers: {
        "company-key": sessionStorage.getItem("company-key"),
      },
    };
    let formData = new FormData();
    const params = {
      color_code: selectedColor,
      use_company_name_as_logo: useCompanyName ? 1 : 0,
      is_deleted: 0,
      company_name: companyName,
      company_logo: image ? image : "",
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    axios
      .post(`${REPORT_SETTING_URL}/store-company-html-report-settings`, formData, Header)
      .then((response) => {
        toast.success(response.data.message);
        fileInput.current.value = "";
        setShowLoader(false);
        setIsChanged(false);
        setRefresh(!refresh);
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.data.success === false) {
            toast.error(error.response.data.message);
            setShowLoader(false);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          console.log("no response", error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };

  return (
    <>
      {showLoader ? <Preloader showPreloader /> : ""}
      <Box className="report-setting-container">
        <Grid container spacing={4}>
          <Grid item xs={8} >
            <Typography className="report-title">Report Settings</Typography>
            <Typography className="report-title-desc">Manage settings for your PDF and interactive reports.</Typography>
            <Card className="report-card-container" elevation={0}>
              <Typography className="report-preview-title">PDF Preview</Typography>
              <div className="report-preview-card-container">
                <ReportPreviewPages defaultLogo={defaultLogo} image={image} selectedColor={selectedColor} useCompanyName={useCompanyName} companyName={companyName} />
              </div>
            </Card>
          </Grid>
          <Grid item xs={4} >
            <Card elevation={0} className="report-card-container-secondary">
              <ReportEditLayout setCompanyName={setCompanyName} lastPublished={lastPublished} isChanged={isChanged} sendSettings={sendSettings} defaultLogo={defaultLogo} setDefaultLogo={setDefaultLogo} setIsChanged={setIsChanged} fileInput={fileInput} image={image} setImage={setImage} colorPalette={colorPalette} selectedColor={selectedColor} setSelectedColor={setSelectedColor} setUseCompanyName={setUseCompanyName} useCompanyName={useCompanyName} companyName={companyName} />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ReportSettingsLayout;
