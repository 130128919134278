import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import RSColorPicker from "./RSColorPicker";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { BootstrapTooltip } from "../../../utils/services";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { apiCall } from "../../../components/agentListView/action";
import { Grid, Typography, Button, FormControlLabel, FormGroup, Checkbox, Dialog, DialogContent, DialogActions, Divider } from "@mui/material";

const ReportEditLayout = (props) => {
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [loader, setLoader] = useState(false);
  const { colorPalette, companyName, setCompanyName, defaultLogo, lastPublished, isChanged, sendSettings, setDefaultLogo, setIsChanged, fileInput, image, setImage, selectedColor, setSelectedColor, useCompanyName, setUseCompanyName } = props;
  const deleteProfilePicture = () => {
    setLoader(true);
    const formdata = { company_key: sessionStorage.getItem("company-key") };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setOpen(false);
          setLoader(false);
          setImage("");
          setDefaultLogo("");
          fileInput.current.value = "";
        } else {
          console.log("else ", message);
          setLoader(false);
        }
      },
      formdata,
      "LOGO_DELETE_RS"
    );
  };


  const handleImageUpload = async (event) => {
    setLoader(true);
    const imageFile = event.target.files[0];

    if (!imageFile) {
      setLoader(false);
      return;
    }

    // Check if the file size is greater than 20MB
    const maxFileSizeMB = 20;
    const fileSizeMB = imageFile.size / (1024 * 1024); // Convert file size to MB

    if (fileSizeMB > maxFileSizeMB) {
      toast.error("File size exceeds 20MB. Please upload a smaller file.");
      setLoader(false);
      return;
    }

    // Compression options
    const options = {
      maxSizeMB: 1,            // Max size in MB (Adjust as needed)
      maxWidthOrHeight: 800,   // Max width/height (Adjust as needed)
      useWebWorker: true,      // Enables web workers for better performance
    };

    try {
      // Compress the image file
      const compressedFile = await imageCompression(imageFile, options);
      // Set the compressed image in the state to preview
      setImage(compressedFile);
      setIsChanged(true);
      setUseCompanyName(false);
      setLoader(false);

    } catch (compressionError) {
      console.error("Compression Error:", compressionError);
      setLoader(false);
    }
  };

  return (
    <>
      <Grid container className="edit-container">
        <Grid item className="edit-title" xs={12}>
          <div style={{ display: "flex", gap: "2px" }}>
            <Typography className="edit-title">HEADER TEXT</Typography>
            <BootstrapTooltip
              title={
                <h1 className="font-13">
                  Customize the header of your PDFs. This text displays by default if you don't have a logo.
                </h1>
              }
            >
              <InfoOutlinedIcon sx={{ fontSize: "15px !important", mt: 0.3, cursor: "pointer" }} />
            </BootstrapTooltip>
          </div>

          <div className="edit-title-div">
            <input
              type="text"
              value={companyName}
              onChange={(e) => { setCompanyName(e.target.value); setIsChanged(true) }}
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                fontSize: "13px",
                width: "100%",
                textAlign: "left",
              }}
            />
          </div>
          <div style={{ display: "flex", gap: "2px", marginTop: "15px" }}>
            <Typography className="edit-title">LOGO</Typography>
            <BootstrapTooltip
              title={
                <h1 className="font-13">
                  Your company logo will replace the header text in all your reports.
                </h1>
              }
            >
              <InfoOutlinedIcon sx={{ fontSize: "15px !important", mt: 0.3, cursor: "pointer" }} />
            </BootstrapTooltip>
          </div>
          <div>
            <div className="upload-image-container" onClick={() => fileInput.current.click()}>
              <input
                ref={fileInput}
                type="file"
                accept="image/*"
                onChange={(e) => {
                  handleImageUpload(e);
                }}
                style={{ display: "none" }}
              />
              {!image && !defaultLogo && <><div>
                <AddCircleIcon className="upload-icon" />
              </div>
                <p className="add-logo-text ">Add logo</p>
                <p className="add-logo-subtext">20MB max</p></>}
              {image && <img className="image-setting" src={typeof image === "string" ? image : URL.createObjectURL(image)} alt="Thumb" />}
              {!image && defaultLogo ? <img src={defaultLogo} className="image-setting" alt="dp" /> : <div className="image-setting"></div>}
            </div>
          </div>
          <Typography className="desc">For best visual results we suggest a logo fills the white area as much as possible.</Typography>
        </Grid>
        {defaultLogo && <Grid item xs={12}>
          <p style={{ paddingTop: "10px", fontSize: "12px", lineHeight: "20px", letterSpacing: 0, fontWeight: "600", color: " #E30303", cursor: "pointer", opacity: defaultLogo ? "1.00" : "0.25", pointerEvents: !defaultLogo && "none", userSelect: "none" }} onClick={() => handleOpen()}>
            <DeleteIcon sx={{ height: "11.5px !important", width: "11.5px" }} /> Delete photo
          </p>
        </Grid>}
        <Grid item xs={12}>
          <FormGroup sx={{ ml: 1 }}>
            <FormControlLabel
              control={
                <Checkbox sx={{ mt: "-17px !important" }} checked={useCompanyName} id="use_company_name"
                  onClick={() => {
                    setUseCompanyName(!useCompanyName);
                    setIsChanged(true);
                  }}
                />
              }
              label={
                <>
                  <Typography className="checkbox">Always use header text</Typography> <Typography className="checkbox-desc">Use the header text instead of your logo in the header of the PDF and HTML report.</Typography>
                </>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mt: 4, mb: 2 }} />
          <Typography className="edit-title">BRAND COLOR</Typography>
          <RSColorPicker color={selectedColor} setColor={setSelectedColor} setIsChanged={setIsChanged} colorPalette={colorPalette} />
          <Divider sx={{ mt: 4, mb: 4 }} />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Button disabled={!isChanged} id="save_report_btn" className="report-settings-footer-save" onClick={sendSettings}>
            Save and apply
          </Button>
          <span style={{ textAlign: "center" }}>
            <Typography className="edit-title">Last Saved: {moment(lastPublished).format("MMMM Do, LT")}</Typography>
            <br />
            <Typography className="edit-title">Changes to these settings will affect ALL reports.</Typography>
          </span>
        </Grid>
      </Grid>
      <Dialog fullWidth={true} className="dialog-container-details report_settings_modal" open={open} onClose={handleClose}>
        <div className="close-wrapper">
          <CloseIcon onClick={handleClose} />
        </div>
        <DialogContent className="dialog-content">
          <Grid container className="permission_change_modal report_setting_content">
            <Typography>Delete confirmation</Typography>
            <Typography>Are you sure you want to delete the company logo?</Typography>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action report_settings_action">
          <Button className="back-btn" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton className="default-btn" onClick={() => deleteProfilePicture()} loading={loader}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportEditLayout;
