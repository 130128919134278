import uuid from "react-uuid";
import { timestamp } from "../../../utils/services";

const copyComments = (comments) => {
  return comments?.map((comment, index) => {
    let commentInputControls = comment.comment_input_controls?.map((commentControl) => {
      return {
        ...commentControl,
        uuid: uuid(),
        comment_input_control_id: uuid(),
        updated_at: timestamp(),
      };
    });

    let commentId = uuid();

    return {
      ...comment,
      uuid: commentId,
      comment_id: commentId,
      is_selected_updated_at: timestamp(comments.length - (index + 1)),
      updated_at: timestamp(),
      comment_input_controls: [...commentInputControls],
    };
  });
};

export function copyLine(line) {
  const newLine = { ...line };
  newLine.comments = copyComments(line.comments);
  newLine.line_input_controls = line.line_input_controls?.map((inputControl) => {
    let inputControlValues = inputControl.values?.map((inputControlValue) => {
      if (inputControlValue.uuid !== undefined) {
        return {
          ...inputControlValue,
          uuid: uuid(),
          created_at: timestamp(),
          updated_at: timestamp(),
        };
      }

      return { ...inputControlValue };
    });

    const inputControlComments = copyComments(inputControl.comments) ?? [];

    return {
      ...inputControl,
      uuid: uuid(),
      line_input_control_id: uuid(),
      updated_at: timestamp(),
      comments: [...inputControlComments],
      values: [...inputControlValues],
    };
  });

  return {
    ...newLine,
    line_id: uuid(),
    uuid: uuid(),
    updated_at: timestamp(),
  };
}

export function copyCategory(category, categoryList, type) {
  let newLines = [];
  category?.lines?.map((line) => {
    newLines.push(copyLine(line));
  });
  return {
    ...category,
    created_at: timestamp(),
    updated_at: timestamp(),
    is_selected: type === "add" ? true : false,
    lines: [...newLines],
    order: categoryList.length + 1,
  };
}
