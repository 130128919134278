import { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";

const SectionMenu = (props) => {
  const {
    from,
    linedata,
    removeLine,
    handleOpen,
    handleClose,
    DeleteLines,
    handleOpenCI,
    isInspection,
    RenameExisting,
    AllCategoryList,
    assignFlagInLine,
    OpenEditableMenu,
    isInspectionLines,
    colorIndicatorList,
    duplicateCateOrLine,
    assignFlagInCategory,
  } = props;

  const [colorMenu, setColorMenu] = useState(false);
  const toggleColorMenu = (open) => setColorMenu(open);
  const MenuItems = [
    {
      title: "Edit",
      closeMenu: true,
      icon: <EditIcon />,
      clickEvent: (e) => OpenEditableMenu(e),
    },
    {
      title: "Rename",
      closeMenu: true,
      icon: <TextFieldsIcon />,
      clickEvent: (e) => RenameExisting(e),
    },
    {
      title: "Assign Color",
      closeMenu: false,
      icon: <ColorLensIcon />,
      clickEvent: (e) => toggleColorMenu(e),
    },
    {
      title: "Duplicate",
      closeMenu: true,
      icon: <ContentCopyIcon />,
      clickEvent: (e) => duplicateCateOrLine(e),
    },

    {
      title: "Remove Form Element",
      closeMenu: true,
      icon: <RemoveCircleOutlineRoundedIcon />,
      clickEvent: (e) => removeLine(e),
    },
    {
      title: "Delete",
      closeMenu: true,
      icon: <DeleteIcon />,
      clickEvent: (e) => DeleteLines(e),
    },
  ];

  if (isInspection && linedata.hasOwnProperty("type") && linedata["type"] === "CheckBox") {
    console.log("The key-value pair exists.");
  } else {
    console.log("The key-value pair does not exist.");
  }

  isInspection === 1 && MenuItems.splice(2, 2);
  isInspection === 1 && MenuItems.splice(0, 1);
  isInspectionLines && MenuItems.splice(0, 1);
  from.split("_")[0] === "additional" && MenuItems.splice(0, 1) && MenuItems.splice(1, 2) && MenuItems.splice(2, 0);
  from.split("_")[0] === "comments" && MenuItems.splice(0, 1) && MenuItems.splice(1, 3) && MenuItems.splice(2, 1);
  const handleClickAway = (e) => {
    handleClose(e);
    toggleColorMenu(false);
  };

  function truncateString(str) {
    if (str.length > 20) {
      return str.slice(0, 15) + '...';
    }
    return str;
  }

  return (
    <ClickAwayListener onClickAway={(e) => handleClickAway(e)}>
      <Grid onClick={(e) => e.stopPropagation(e)} className="sectionMenu">
        <CloseIcon onClick={(e) => handleClickAway(e)} className="sectionMenu__close" />
        <div className="sectionMenu__items">
          {MenuItems.map((items, index) =>
            (items.title === "Remove Form Element" && from.split("_")[0] !== "additional") || (items.title === "Remove Form Element" && AllCategoryList?.template?.is_texas) ? null : (
              <div
                style={{ paddingBottom: "16px" }}
                key={index}
                onClick={(e) => {
                  if (items?.title === "Assign Color") return;
                  items.clickEvent();
                  items.closeMenu && handleOpen(e);
                }}
                onMouseEnter={(e) => {
                  items?.title === "Assign Color" ? items.clickEvent(true) : toggleColorMenu(false);
                }}>
                {items.icon}
                <span className="sectionMenu__items__text">{items.title}</span>
                {items?.title === "Assign Color" && <ArrowForwardIosIcon className="sectionMenu__items__arrow" />}
              </div>
            )
          )}
        </div>
        {colorMenu && (
          <div className="colorMenu">
            {colorIndicatorList?.filter((e) => e?.is_deleted !== 1)?.length > 0 ? (
              colorIndicatorList?.filter((e) => e?.is_deleted !== 1)?.map((val) => {
                return (
                  <>
                    <div
                      onClick={(e) => {
                        if (from.split("_")[0] === "CATEGORY") {
                          assignFlagInCategory(val, linedata?.uuid, linedata?.category_id, linedata?.inspector_flagging_guids);
                        } else if (from.split("_")[0] === "SUBCATEGORY") {
                          assignFlagInLine(val, linedata);
                        }
                        handleClose(e);
                      }}
                      style={{
                        display: "flex",
                        gap: "8px",
                        padding: "4px 8px",
                        borderRadius: "6px",
                        border: `${linedata?.inspector_flagging_guids?.includes(val?.guid) ? "1px solid #4282E2" : ""}`,
                        background: `${linedata?.inspector_flagging_guids?.includes(val?.guid) ? "#F0F6FF" : ""}`,
                        marginBottom: "12px",
                      }}>
                      <div className="color_circle" style={{ backgroundColor: val.color_code }}></div>
                      <p className="color_title">{truncateString(val?.name)}</p>
                      {linedata?.inspector_flagging_guids?.includes(val?.guid) && <CheckIcon sx={{ color: "#4282E2", marginLeft: "auto" }} />}
                    </div>
                  </>
                );
              })
            ) : (
              <p className="colorIndicatorsection">No roles assigned</p>
            )}
            <Divider />
            <div
              style={{ paddingTop: "16px" }}
              onClick={(e) => {
                handleOpenCI();
                handleOpen(e);
              }}>
              <EditIcon />
              <span className="sectionMenu__items__text">Edit list</span>
            </div>
          </div>
        )}
      </Grid>
    </ClickAwayListener>
  );
};

export default SectionMenu;